<template>
  <div class="bannerForm">

    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="banner_id">Banner Identifier:</label>
        <input type="text" v-model="banner.banner_id"
          :class="{ 'form-control': true, 'is-invalid': errors.has('banner_id') }" name="banner_id" id="banner_id"
          placeholder="Banner Identifier" v-validate="'required'" />
        <div class="invalid-feedback">
          Please provide a valid banner identifier that is unique.
        </div>
      </div>

      <div class="form-group">
        <label for="video">Page Identifier:</label>
        <select class="form-control" name="page_id" v-model="banner.page_id">
          <option v-for="page in pages" v-bind:value="page.value" v-bind:key="page.value">
            {{ page.title }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="banner_description">Short Description:</label>
        <input type="text" v-model="banner.banner_description" :class="{
          'form-control': true,
          'is-invalid': errors.has('banner_description')
        }" name="banner_description" id="banner_description" placeholder="" v-validate="'required'" />
        <div class="invalid-feedback">
          Please provide a valid banner description.
        </div>
      </div>

      <div class="form-group">
        <label for="video">Type:</label>
        <select class="form-control" name="thumbnail_grid" v-model="banner.banner_type">
          <option v-for="bannerType in bannerTypes" v-bind:value="bannerType.value" v-bind:key="bannerType.title">
            {{ bannerType.title }}
          </option>
        </select>
      </div>

      <fieldset v-if="banner.banner_type == 'featured'">
        <div class="form-group">
          <label for="bd_header">Header:</label>
          <input type="text" v-model="banner.banner_data.header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header') }" name="bd_header" id="bd_header"
            placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>
        <div class="form-group">
          <label for="bd_featured_image01">Featured Image #1:</label>
          <input type="text" v-model="banner.banner_data.featured_images[0]" :key="0"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image01') }" name="bd_featured_image01"
            id="bd_featured_image01" placeholder="Featured Image 01" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_image02">Featured Image #2:</label>
          <input type="text" v-model="banner.banner_data.featured_images[1]" :key="1"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image02') }" name="bd_featured_image02"
            id="bd_featured_image02" placeholder="Featured Image 02" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_image03">Featured Image #3:</label>
          <input type="text" v-model="banner.banner_data.featured_images[2]" :key="2"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image03') }" name="bd_featured_image03"
            id="bd_featured_image03" placeholder="Featured Image 03" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_image04">Featured Image #4:</label>
          <input type="text" v-model="banner.banner_data.featured_images[3]" :key="3"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image04') }" name="bd_featured_image04"
            id="bd_featured_image04" placeholder="Featured Image 04" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_image05">Featured Image #5:</label>
          <input type="text" v-model="banner.banner_data.featured_images[4]" :key="4"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image05') }" name="bd_featured_image05"
            id="bd_featured_image05" placeholder="Featured Image 05" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_image06">Featured Image #6:</label>
          <input type="text" v-model="banner.banner_data.featured_images[5]" :key="5"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image06') }" name="bd_featured_image06"
            id="bd_featured_image06" placeholder="Featured Image 06" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_image07">Featured Image #7:</label>
          <input type="text" v-model="banner.banner_data.featured_images[6]" :key="6"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image07') }" name="bd_featured_image07"
            id="bd_featured_image07" placeholder="Featured Image 07" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_image08">Featured Image #8:</label>
          <input type="text" v-model="banner.banner_data.featured_images[7]" :key="7"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_featured_image08') }" name="bd_featured_image08"
            id="bd_featured_image08" placeholder="Featured Image 08" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 942 width.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_featured_text">Featured Text:</label>
          <editor api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86" name="bd_featured_text" id="bd_featured_text"
            v-model="banner.banner_data.featured_text" class="wysiwyg-editor" :init="{
              plugins:
                'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker'
            }"
            toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile">
          </editor>
        </div>

      </fieldset>

      <fieldset v-if="banner.banner_type == 'banner'">
        <legend>Banner Data</legend>

        <div class="form-group">
          <label for="bd_header_image">Header Image:</label>
          <input type="text" v-model="banner.banner_data.header_image"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header_image') }" name="bd_header_image"
            id="bd_header_image" placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid header image url 1920x1080.
          </div>
        </div>
        <div class="form-group">
          <label for="bd_header">Header:</label>
          <input type="text" v-model="banner.banner_data.header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_header') }" name="bd_header" id="bd_header"
            placeholder="Banner Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_header_placement">Header Placement:</label>
          <select class="form-control" name="bd_header_placement" v-model="banner.banner_data.header_placement">
            <option value="">.. Do not set</option>
            <option v-for="placementOption in headerPlacementOptions" v-bind:value="placementOption.value"
              v-bind:key="placementOption.value">
              {{ placementOption.title }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="bd_sub_header">Sub Header:</label>
          <input type="text" v-model="banner.banner_data.sub_header"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_sub_header') }" name="bd_sub_header"
            id="bd_sub_header" placeholder="Banner Sub Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner sub header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_sub_header_placement">Sub Header Placement:</label>
          <select class="form-control" name="bd_sub_header_placement" v-model="banner.banner_data.sub_header_placement">
            <option value="">.. Do not set</option>
            <option v-for="placementOption in headerPlacementOptions" v-bind:value="placementOption.value"
              v-bind:key="placementOption.value">
              {{ placementOption.title }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="bd_sub_header_2">Sub Header 2:</label>
          <input type="text" v-model="banner.banner_data.sub_header_2"
            :class="{ 'form-control': true, 'is-invalid': errors.has('bd_sub_header_2') }" name="bd_sub_header_2"
            id="bd_sub_header_2" placeholder="Banner Sub Header" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid banner sub header title.
          </div>
        </div>

        <div class="form-group">
          <label for="bd_sub_header_2_placement">Sub Header 2 Placement:</label>
          <select class="form-control" name="bd_sub_header_2_placement"
            v-model="banner.banner_data.sub_header_2_placement">
            <option value="">.. Do not set</option>
            <option v-for="placementOption in headerPlacementOptions" v-bind:value="placementOption.value"
              v-bind:key="placementOption.value">
              {{ placementOption.title }}
            </option>
          </select>
        </div>


      </fieldset>

      <fieldset v-if="banner.banner_type == 'popular'">
        <legend>Banner Data</legend>
        <div class="form-group">
          <label for="description">Description:</label>

          <textarea rows="10" cols="10" v-model="banner.banner_data.description" :class="{
            'form-control': true,
            'is-invalid': errors.has('description')
          }" name="description" id="description" placeholder="" v-validate="'required'"></textarea>
          <div class="invalid-feedback">
            Please provide a valid description.
          </div>
        </div>

        <div class="form-group">
          <label for="title">Title:</label>
          <input type="text" v-model="banner.banner_data.title"
            :class="{ 'form-control': true, 'is-invalid': errors.has('title') }" name="title" id="title"
            placeholder="Title" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid title.
          </div>
        </div>

        <div class="form-group">
          <label for="title">Subtitle:</label>
          <input type="text" v-model="banner.banner_data.subtitle"
            :class="{ 'form-control': true, 'is-invalid': errors.has('subtitle') }" name="subtitle" id="subtitle"
            placeholder="Subtitle" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid subtitle.
          </div>
        </div>


        <div class="form-group">
          <label for="link">Link:</label>
          <input type="text" v-model="banner.banner_data.link"
            :class="{ 'form-control': true, 'is-invalid': errors.has('link') }" name="link" id="link" placeholder="Link"
            v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid link.
          </div>
        </div>

        <div class="form-group">
          <label for="goto_title">Go To Link Title:</label>
          <input type="text" v-model="banner.banner_data.goto_title"
            :class="{ 'form-control': true, 'is-invalid': errors.has('goto_title') }" name="goto_title" id="goto_title"
            placeholder="Go To Link Title" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid go to link title.
          </div>
        </div>
        <div class="form-group">
          <label for="goto_link">Go To Link:</label>
          <input type="text" v-model="banner.banner_data.goto_link"
            :class="{ 'form-control': true, 'is-invalid': errors.has('goto_link') }" name="goto_link" id="goto_link"
            placeholder="Go To Link" v-validate="'required'" />
          <div class="invalid-feedback">
            Please provide a valid go to link.
          </div>
        </div>
      </fieldset>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import BannerService from "../services/BannerService";
import BannerTypes from "../data/banner-types.json";
import Pages from "../data/pages.json";
import HeaderPlacementOptions from "../data/header-placement-options.json";
import Editor from "@tinymce/tinymce-vue";


export default {
  components: {
    Editor
  },

  methods: {
    saveChanges() {

      if (!isNaN(this.$route.params.id)) {
        BannerService.update(this.$route.params.id, this.banner)
          .then(response => {
            if (response.status === 200) {
              alert("Record updated successfully");
            }
          });
      } else if (this.$route.params.id == "add") {
        BannerService.create(this.banner)
          .then(response => {
            if (response.status === 200) {
              alert("Record created successfully");
            }
          });
      }

    }
  },

  data() {
    return {
      bannerTypes: BannerTypes,
      headerPlacementOptions: HeaderPlacementOptions,
      pages: Pages,
      banner: {
        banner_data: {
          call_to_action: {},
          featured_images: []
        }
      }
    };
  },

  created() {
    if (!isNaN(this.$route.params.id)) {
      BannerService.findById(this.$route.params.id)
        .then(response => {
          let banner = response.data;
          if (!banner.banner_data.call_to_action) {
            banner.banner_data.call_to_action = {};
          }

          if (!banner.banner_data.featured_images) {
            banner.banner_data.featured_images = [];
          }
          this.banner = banner;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>
<style></style>
