<template>
  <div class="blogForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          v-model="blog.title"
          :class="{ 'form-control': true, 'is-invalid': errors.has('title') }"
          name="title"
          id="title"
          placeholder="Title"
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid name.
        </div>
      </div>

      <div class="form-group">
        <label for="title">Slug:</label>
        <input
          type="text"
          v-model="blog.slug"
          :class="{ 'form-control': true, 'is-invalid': errors.has('slug') }"
          name="slug"
          id="slug"
          placeholder="Slug"
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid slug.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input
          type="text"
          v-model="blog.meta_title"
          class="form-control"
          name="meta_title"
          id="meta_title"
          placeholder=""
        />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea
          v-model="blog.meta_description"
          class="form-control"
          name="meta_description"
          id="meta_description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>

      <div class="form-group">
        <label for="author">Author:</label>
        <input
          type="text"
          v-model="blog.author"
          :class="{ 'form-control': true, 'is-invalid': errors.has('author') }"
          name="author"
          id="author"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid author.
        </div>
      </div>

      <div class="form-group">
        <label for="excerpt_image">Excerpt Image:</label>
        <input
          type="text"
          v-model="blog.excerpt_image"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('excerpt_image')
          }"
          name="excerpt_image"
          id="excerpt_image"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid excerpt image link.
        </div>
      </div>

      <div class="form-group">
        <label for="excerpt">Excerpt:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="excerpt"
          id="excerpt"
          v-model="blog.excerpt"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker'
          }"
          toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile"
        ></editor>
      </div>

      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Media</legend>
          <div class="col-sm-10">
            <div class="row">
              <div class="form-check">
                <input
                  type="radio"
                  class="form-check-input"
                  name="media_type"
                  id="media_type_video"
                  value="video"
                  v-model="blog.media.type"
                />
                <label for="media_type_video" class="form-check-label"
                  >Video</label
                >
              </div>
              <div class="form-check" style="margin-left: 15px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="media_type"
                  id="media_type_image"
                  value="image"
                  v-model="blog.media.type"
                />
                <label for="media_type_image" class="form-check-label"
                  >Image</label
                >
              </div>
            </div>
            <div class="row">
              <label for="media_link">Link:</label>
              <input
                type="text"
                v-model="blog.media.link"
                :class="{
                  'form-control': true,
                  'is-invalid': errors.has('media_link')
                }"
                name="media_link"
                id="media_link"
                placeholder=""
                v-validate="'required'"
              />
              <div class="invalid-feedback">
                Please provide a valid media link.
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <div class="form-group">
        <label for="description">Post:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="body"
          id="body"
          v-model="blog.post"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker'
          }"
          toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile"
        ></editor>
      </div>

      <div class="form-group">
        <label for="release_date">Release Date:</label>
        <datepicker
          placeholder="Select Date"
          v-model="blog.release_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";
import toSlugCase from 'to-slug-case';

export default {
  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API + "/blogs/" + this.$route.params.id,
              this.blog,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(process.env.VUE_APP_ROOT_API + "/blogs", this.blog, options)
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    }
  },

  data: function() {
    return {
      blog: {
        media: {
          type: "video",
          link: ""
        }
      }
    };
  },

  components: {
    datepicker: Datepicker,
    editor: Editor
  },

  created: function() {
    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/blogs/" + this.$route.params.id)
        .then(response => {
          this.blog = response.data;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>

<style></style>
