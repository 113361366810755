import Vue from "vue";
import AccountService from "./AccountService";

export default {
  create(bannerRecord) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/banner";
    let options = AccountService.getSessionHeaders();

    return  Vue.axios.post(apiMethodUrl, bannerRecord, options);
  },

  update(id, bannerRecord) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/banner/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.patch(apiMethodUrl, bannerRecord, options);
  },

  delete(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/banner/" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.delete(apiMethodUrl, options);
  },

  findById(id) {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/banner?bannerId=" + id;
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },

  all() {
    let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/banner";
    let options = AccountService.getSessionHeaders();

    return Vue.axios.get(apiMethodUrl, options);
  },
};
